<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import DetailTreatment from './detail-treatment';
/**
 * Advanced table component
 */
export default {
  page: {
    title: "Treatments",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DetailTreatment },
  computed: {
    createAction() {
      if (this.$store.getters.checkPermission('CREATE_TREATMENT')) {
        return { title: 'Create Treatment', link: '/treatment/create'}
      } else {
        return null
      }
    }
  },
  data() {
    return {
      title: "List Of Treatments",
      items: [
        {
          text: "Dashboard",
          href: "/home"
        },
        {
          text: "Treatments",
          active: true
        }
      ],
      showId: null,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "createdAt",
      sortDesc: false,
      fields: [
        'No',
        { key: "name", sortable: true },        
        { key: "sku", sortable: true },
        { key: "price", sortable: true },
        { key: "duration", sortable: true },
        { key: "status", sortable: true },
        'Action'
      ],
      deleteId: null
    };
  },
  methods: {
    async fetchTreatments(ctx) {
      console.log(ctx)
      const promise = this.$axios.get(`treatment`, {
        params: {
          page: ctx.currentPage,
          limit: ctx.perPage,
          search: ctx.filter,
          sortBy: ctx.sortBy,
          sortType: (ctx.sortDesc)? 1 : -1
        }
      })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // Pluck the array of items off our axios response
        const items = response.data.result.treatment
        this.totalRows = response.data.result.total
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    }, 
    confirmDelete(treatmentId) {
      this.deleteId = treatmentId
      this.$bvModal.show('modal-delete')
    },
    deleteData() {
      this.$axios.delete(`treatment/${this.deleteId}`)
        .then( response => {
          console.log(response.data)          
          Swal.fire("Success", "Treatment Deleted Successfully", "success");
          this.$refs.table.refresh();
        }).catch( error => {
          console.log(error.response)
        })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :action="createAction" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="table"
                :items="fetchTreatments"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :empty-text="'There are no records to show'"
                :empty-filtered-text="'There are no records matching your request'"
              >
                <!-- A virtual column -->
                <template v-slot:cell(No)="data">
                  {{ (currentPage - 1) * perPage + (data.index + 1) }}
                </template>
                <!-- Price -->
                <template v-slot:cell(price)="data">
                  {{ data.item.price | convertToRupiah }}
                </template>
                <!-- Duration -->
                <template v-slot:cell(duration)="data">
                  {{ data.item.duration }} minutes
                </template>
                <!-- Status -->
                <template v-slot:cell(status)="data">
                  <b-badge variant="success" class="ml-1" v-if="data.item.status">active</b-badge>
                  <b-badge variant="secondary" class="ml-1" v-else>not active</b-badge>
                </template>
                <!-- Aciton -->
                <template v-slot:cell(action)="data">                  
                  <b-button variant="outline-info" size="sm" @click.prevent="showId = data.item.treatmentId">Detail</b-button>
                  <b-button v-if="$store.getters.checkPermission('UPDATE_TREATMENT')" variant="outline-primary" class="ml-2 mr-2" size="sm" :to="`/treatment/edit/${data.item.treatmentId}`">Edit</b-button>
                  <b-button v-if="$store.getters.checkPermission('DELETE_TREATMENT')" variant="outline-danger" size="sm" @click.prevent="confirmDelete(data.item.treatmentId)">Delete</b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <detail-treatment :id="showId" @close="showId = null"></detail-treatment>

    <b-modal
      id="modal-delete"
      centered
      title="Warning!"
      title-class="font-18"
      @cancel="deleteId = null"
      @ok="deleteData"
    >
      <p>are you sure you want to delete this data ?</p>
    </b-modal>
  </Layout>
</template>