<template>
  <div>
    <b-modal
      hide-footer
      size="xl"
      id="modal-detail"
      centered
      title="Detail Treatment"
      title-class="font-18" 
      @close="$emit('close')"
      no-close-on-backdrop
      >
      <div class="d-inline-block w-100 pad-10" v-if="treatment" >
        <div class="row">
          <div class="col-md-12 shadow-card for-head">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-white font-weight-bold pb-6 border-bottom">Data Treatment</span>
              </div>
              <div class="w-20 pl-12 pr-12">
                <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                  Name 
                </span>
                <span class="text-white font-weight-bold">{{ treatment.name}}</span>
              </div> 
              <div class="w-10 pl-12 pr-12">
                <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                  SKU 
                </span>
                <span class="text-white font-weight-bold">{{ treatment.sku}}</span>
              </div> 
              <div class="w-10 pl-12 pr-12">
                <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                  Duration 
                </span>
                <span class="text-white font-weight-bold">{{ treatment.duration}} Minutes</span>
              </div> 
              <div class="w-15 pl-12 pr-12">
                <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                  Price 
                </span>
                <span class="text-white font-weight-bold"> Rp.{{ treatment.price | convertToRupiah}}</span>
              </div> 
              <div class="w-10 pl-12 pr-12">
                <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                  Status 
                </span>
                <span class="text-white font-weight-bold">{{ treatment.status == 1 ? 'Active' : 'Not Active' }}</span>
              </div> 
              <div class="w-35 pl-12 pr-12">
                <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                  Categories 
                </span>
                <template v-for="(category, i) in treatment.categories">
                  <span :key="i" class="text-white font-weight-bold mr-1">{{ category.name }},</span>
                </template>
              </div> 
              <div class="w-50 pl-12 pr-12">
                <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                  Description 
                </span>
                <span class="text-white font-weight-bold">{{ treatment.description }}</span>
              </div>
              <div class="w-30 pl-12 pr-12">
                <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                  Availability 
                </span>
                <template v-for="(index, i) in treatment.availability">
                  <b-badge :key="i" variant="warning" class="mr-1">{{ index | capitalize }}</b-badge>
                </template>
              </div>
              <div class="w-20 pl-12 pr-12">
                <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                  Tags 
                </span>
                <span :key="i" v-for="(index, i) in treatment.tags" class="text-white font-weight-bold">#{{ index }} </span>
              </div>
            </div> 
          </div>
          <div class="col-md-6 shadow-card">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Locations</span>
              </div>
              <template v-for="(items, i) in location">
                <div class="col-md-12 mb-3" :key="i" v-if="items.isPresent"> 
                  <div>
                    <span class="text-grey-dark font-weight-bold"> <i class="fa fa-check text-success"></i> &nbsp; {{ items.name}}</span>
                  </div>
                </div>  
              </template>
            </div>
          </div>
          <div class="col-md-6 shadow-card">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Equipment</span>
              </div>
              <template v-for="(items, i) in equipment">
                <div class="col-md-12 mb-3"  :key="i" v-if="items.isPresent"> 
                  <div>
                    <span class="text-grey-dark font-weight-bold"> 
                      <i class="fa fa-check text-success"></i> &nbsp; {{ items.name}}
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div> 
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'detail-treatment',
  props: {
    id: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      treatment: null,
      location:[],
      equipment:[]
    }
  },
  watch: {
    id(newVal) {
      if (newVal !== null) {
        this.getDetail()
      } 
    }
  },
  methods: {
    getDetail() {
      this.$bvModal.show('modal-detail')
      this.$axios.get(`treatment/${this.id}`)
        .then( response => {
          this.treatment = response.data.result.treatment
          console.log(this.treatment)
          this.getLocation()
          this.getEquipment()
        })
    },
    getLocation(){
      this.$axios.get(`treatment/${this.id}/location`)
        .then( response => {
          this.location = response.data.result.locations
        })
    },
    getEquipment(){
      this.$axios.get(`treatment/${this.id}/equipment`)
        .then( response => {
          this.equipment = response.data.result.equipments
          console.log(this.equipment) 
        })
    }
  }
}
</script>